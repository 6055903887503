import React, { useState } from "react";
import Line from "@components/line/Line";
import { ReactComponent as QuestionsArrow } from "@assets/questionsArrow.svg";
import { ReactComponent as QuestionsArrowDown } from "@assets/questionsArrowDown.svg";
import { FAQ_DATA } from "@constants/constants";

const QuestionsBlock = () => {
  const [openQuestionIndex, setOpenQuestionIndex] = useState<number | null>(
    null,
  );

  const toggleQuestion = (index: number) => {
    setOpenQuestionIndex(openQuestionIndex === index ? null : index);
  };

  return (
    <section id="faq" className="lg:mt-20 mt-[52px] 1xl:px-5 px-6 md:px-12">
      <h2 className="text-[#2D2C2A] lg:mb-6 mb-4">
        ВОПРОСЫ <br />
        <span className="text-transparent 1xl:text-s-2 text-s-3-xs">
          ЧАСТОЗАДАВАЕМЫЕ
        </span>
      </h2>
      <div className="lg:space-y-6 space-y-3">
        {FAQ_DATA.map((item) => (
          <div
            key={item.id}
            className="border-t-[1px] border-[#303024] lg:pt-6 pt-3"
          >
            <button
              onClick={() => toggleQuestion(item.id)}
              className="w-full text-left flex justify-between items-center focus:outline-none"
            >
              <h6 className="text-[#2D2C2A] font-semibold">{item.question}</h6>
              <span
                className={`transition-transform duration-300 ${
                  openQuestionIndex === item.id ? "rotate-180" : "rotate-0"
                }`}
              >
                {openQuestionIndex === item.id ? (
                  <QuestionsArrow className="w-[15px] h-[8px] sm:w-[24px] sm:h-auto lg:w-[34px] lg:h-[16px] fill-[#C6BF8B]" />
                ) : (
                  <QuestionsArrowDown className="w-[15px] h-[8px] sm:w-[24px] sm:h-auto lg:w-[34px] lg:h-[16px] fill-current" />
                )}
              </span>
            </button>
            <div
              className={`overflow-hidden ${
                openQuestionIndex === item.id
                  ? "animate-expand"
                  : "animate-collapse"
              }`}
            >
              <>
                <Line borderColor="border-[#C6BF8B]" />
                <h6 className="lg:mt-6 mt-3 font-normal whitespace-pre-line">
                  {item.answer}
                </h6>
              </>
            </div>
          </div>
        ))}
        <Line borderColor="mt-3 border-[#303024]" />
      </div>
    </section>
  );
};

export default QuestionsBlock;
