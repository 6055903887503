import React, { useState } from "react";

import ButtonList from "../components/buttonList/ButtonList";
import womanImage from "../public/assets/womanImage.png";

const VideoBannerWithStats = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayButtonClick = () => setIsPlaying(!isPlaying);

  return (
    <section id="how-it-works">
      <div className="flex flex-col lg:flex-row items-stretch 1xl:mt-20 xl:mt-14 m:mt-10 mt-6 m:gap-3 m:h-auto 1xl:gap-5 1xl:h-[600px] mb-6 1xl:px-5 px-6 md:px-12">
        <div className="relative flex-grow flex-col">
          <img
            src={womanImage}
            alt="video"
            className="1xl:w-full 1xl:h-full w-auto h-auto object-cover 1xl:rounded-[39px] rounded-[14px]"
          />
          <div className="absolute inset-0 flex justify-center items-center">
            <button
              className="relative rounded-full 1xl:h-32 1xl:w-32 xl:h-20 xl:w-20 h-12 w-12 bg-[#C6BF8B] flex justify-center items-center"
              onClick={handlePlayButtonClick}
            >
              <div className="absolute left-[42%] top-[38%] xl:left-[38%] xl:top-[30%] w-0 h-0 xl:border-t-[16.88px] border-t-[6.6px] border-t-transparent xl:border-l-[29.25px] border-l-[10.17px] border-l-white xl:border-b-[16.88px] border-b-[6.6px] border-b-transparent" />
            </button>
          </div>
          <h2 className="absolute inset-0 flex justify-center 1xl:top-52 xl:top-40 top-24 items-center text-transparent 1xl:text-white-2 text-white-2-xs">
            КАК ЭТО РАБОТАЕТ?
          </h2>
        </div>
        <div className="flex flex-col justify-center flex-grow bg-[#303024] 1xl:rounded-[39px] rounded-[14px] 1xl:px-12 px-4 m:px-6 md:px-8 1xl:py-12 m:py-5 py-4 m:mt-0 mt-2">
          <div className="xl:mb-10 md:mb-4 m:mb-3 mb-2.5 text-[#F8EFE8]">
            <h2 className={`1xl:mb-3.5 xl:mb-2 text-[#F8EFE8]`}>НА 50%</h2>
            <h5 className="max-w-[256px] font-normal">
              увеличилось число довольных клиентов
            </h5>
          </div>
          <div className="xl:mb-10 md:mb-4 m:mb-3 mb-2.5 text-[#F8EFE8] mx-auto 1xl:mx-0">
            <h2 className={`1xl:mb-3.5 xl:mb-2 text-[#C6BF8B]`}>НА 30%</h2>
            <h5 className="max-w-[256px] font-normal">
              выросла конверсия продаж
            </h5>
          </div>
          <div className="text-[#F8EFE8] ml-auto">
            <h2 className={`1xl:mb-3.5 xl:mb-2 text-[#F8EFE8] `}>НА 20%</h2>
            <h5 className="sm:max-w-[300px] max-w-[190px] font-normal">
              сократили время на обработку заявок
            </h5>
          </div>
        </div>
      </div>
      <div className="1xl:px-5 lg:px-12">
        <ButtonList />
      </div>
    </section>
  );
};

export default VideoBannerWithStats;
