import React, { useEffect, useRef, useState } from "react";

interface AnimatedElementProps {
  id: string;
  children: React.ReactNode;
  animationIn: string;
  animationOut?: string;
  threshold?: number;
}

const AnimatedElement: React.FC<AnimatedElementProps> = ({
  id,
  children,
  animationIn,
  animationOut,
  threshold = 0.1,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold },
    );

    const currentElement = elementRef.current;

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [threshold]);

  return (
    <div
      ref={elementRef}
      id={id}
      className={`transition-transform duration-1000 ${
        isVisible ? animationIn : animationOut ? animationOut : "opacity-0"
      }`}
    >
      {children}
    </div>
  );
};

export default AnimatedElement;
