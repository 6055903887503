import React, { useState } from "react";

import Button from "@components/button/Button";
import { EmailService } from "@services/emailService";

interface IButtonWithInput {
  placeholder: string;
  width: string;
}

const ButtonWithInput: React.FC<IButtonWithInput> = ({
  placeholder,
  width,
}) => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email) {
      alert(`Пожалуйста, введите email`);
      return;
    }
    setIsSubmitting(true);
    try {
      await EmailService.sendEmailAsync(email);
      alert(`Email ${email} успешно отправлен`);
      setEmail("");
    } catch (error) {
      alert("Сообщение не отправленно");
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex w-full justify-center items-center flex-col m:flex-row drop-shadow-[2px_2px_2px_rgba(45,44,42,0.1)]"
    >
      <input
        type="email"
        placeholder={placeholder}
        value={email}
        onChange={handleInputChange}
        className={`xl:p-5 m:p-4 p-3 m:pl-7 pl-[19px] rounded-[54px] focus:outline-none ${width} placeholder-black 1xl:text-xl text-[14px] sm:text-base font-light`}
      />
      <Button
        textSize="1xl:text-[20px] sm:text-base"
        width="m:w-[180px] w-full"
        margin="1xl:ml-[-185px] xl:ml-[-187px] m:ml-[-185px] m:mt-0 mt-2"
        background="bg-[#AACC24]"
        disabled={isSubmitting}
      >
        Отправить
      </Button>
    </form>
  );
};

export default ButtonWithInput;
