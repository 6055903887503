import { useState, useCallback } from "react";
import { EmailService } from "@services/emailService";

export const useContactForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    comment: "",
    phoneNumber: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      if (name === "phoneNumber") {
        const formattedValue = value.replace(/\D/g, "").replace(/^(\d)/, "+$1");
        setFormData((prev) => ({ ...prev, [name]: formattedValue }));
      } else if (name === "comment" && value.length <= 1000) {
        setFormData((prev) => ({ ...prev, [name]: value }));
      } else if (name !== "comment") {
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    },
    [],
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      try {
        setIsLoading(true);
        await EmailService.sendEmailAsync(
          formData.email,
          formData.comment,
          formData.phoneNumber,
        );
        setIsSubmitted(true);
        setFormData({ email: "", comment: "", phoneNumber: "" });
      } catch (error) {
        alert("Failed to send email");
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      form.reportValidity();
    }
  };

  return {
    formData,
    isSubmitted,
    isLoading,
    handleInputChange,
    handleSubmit,
    setIsSubmitted,
  };
};
