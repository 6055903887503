import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";

import Button from "@components/button/Button";
import ButtonWithInput from "@components/buttonWithInput/ButtonWithInput";
import useResponsive from "@components/responsiveHandler/ResponsiveHandler";

import car from "@assets/car.png";
import { ReactComponent as CheckMark } from "@assets/checkmark.svg";
// import { ReactComponent as Strip } from "@assets/strip.svg";
import { PLANS } from "@constants/constants";

const UsageRate = () => {
  const handleClick = () => {
    const element = document.getElementById("contact");
    if (element !== null) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const { isMobile, isTablet, isBigTablet } = useResponsive();

  const handlePrevClick = () => {
    const newIndex = (currentIndex - 1 + PLANS.length) % PLANS.length;
    setCurrentIndex(newIndex);
  };

  const handleNextClick = () => {
    const newIndex = (currentIndex + 1) % PLANS.length;
    setCurrentIndex(newIndex);
  };

  const getVisiblePlans = () => {
    if (isMobile) {
      return [PLANS[currentIndex]];
    } else if (isTablet || isBigTablet) {
      return [PLANS[currentIndex], PLANS[(currentIndex + 1) % PLANS.length]];
    }
    return PLANS;
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNextClick,
    onSwipedRight: handlePrevClick,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <section
      id="pricing"
      className="1xl:mt-24 lg:mt-20 sm:mt-14 mt-12 1xl:px-5 px-6 md:px-12"
    >
      <h2 className="1xl:mb-10 mb-4 text-center text-[#2D2C2A]">
        СТОИМОСТЬ{" "}
        <span className="text-transparent 1xl:text-s-2 text-s-3-xs">
          ТАРИФОВ
        </span>
      </h2>
      <div
        {...swipeHandlers}
        className={`flex ${isMobile ? "overflow-x-auto" : "gap-6"} overflow-hidden`}
      >
        {getVisiblePlans().map((plan) => (
          <div
            key={plan.name}
            className={`flex flex-col items-center bg-white rounded-[16px] border-[1px] lg:py-10 py-7 lg:px-10 px-6 relative ${plan.popular ? " border-[#C6BF8B]" : "border-[#303024]"}`}
            style={{
              width: isMobile
                ? "100%"
                : isTablet || isBigTablet
                  ? "calc(50% - 1rem)"
                  : "calc(33.33% - 1rem)",
            }}
          >
            {/*{plan.popular && (*/}
            {/*  <div className="absolute top-0 left-0 z-0">*/}
            {/*    <Strip*/}
            {/*      className={`sm:w-[80px] sm:h-[75.5px] md:w-[108px] md:h-[102px] w-[108px] h-[102px]`}*/}
            {/*    />*/}
            {/*    <p className="absolute lg:top-7 md:top-[30px] sm:top-5 top-[30px] lg:-left-[5px] md:-left-[2px] sm:-left-[14px] -left-[3px] transform rotate-[-42.62deg] text-[#303024] lg:text-xs text-[11px] font-bold px-2 py-1">*/}
            {/*      ПОПУЛЯРНЫЙ*/}
            {/*    </p>*/}
            {/*  </div>*/}
            {/*)}*/}
            <h3 className="text-transparent 1xl:text-price-2 xl:text-[24px] 1xl:text-[32px] text-price-2-xs z-10">
              {plan.name}
            </h3>
            <h2 className="relative text-[#2E2E2E] 1xl:mt-6 mt-4 text-[24px] lg:text-[32px] xl:leading-[36px] 1xl:text-[48px]">
              {plan.price}
              <span className="absolute xl:text-base text-[14px] font-normal xl:top-0 -top-1 -right-14">
                /месяц
              </span>
            </h2>
            <h6 className="1xl:mb-8 lg:mb-6 mb-4 mt-2 max-w-[300px] font-normal text-[#2E2E2E] opacity-60 text-center">
              {plan.description}
            </h6>
            <button
              className={`${plan.buttonColor} text-white 1xl:font-bold font-medium lg:py-[17px] py-[10px] lg:px-[61px] px-[43px] rounded-full mb-6`}
              onClick={handleClick}
            >
              {plan.buttonText}
            </button>
            <ul className="list-none text-left">
              {plan.features.map((feature) => (
                <li key={feature.id} className="flex items-start mb-2.5">
                  <span className="mr-2">
                    <CheckMark />
                  </span>
                  <h6 className="font-normal text-[#2E2E2E] opacity-60">
                    {feature.text}
                  </h6>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      {(isMobile || isTablet || isBigTablet) && (
        <div className="flex justify-center mt-4">
          {PLANS.map((plans, index) => (
            <span
              key={plans.name}
              className={`h-2 sm:h-2.5 w-2 sm:w-2.5 mx-1 rounded-full ${index === currentIndex ? "bg-[#AACC24]" : "bg-white"}`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      )}
      <div className="hidden m:flex m:flex-col xl:flex-row items-center m:mt-12 mt-24 lg:mt-20 1xl:mt-24 py-5 1xl:py-10 xl:py-8 xl:pl-14 pr-7 bg-[#C6BF8B] rounded-[133px] gap-4 xl:gap-2">
        <h3 className="text-[#303024]">ПОПРОБОВАТЬ ПРИЛОЖЕНИЕ</h3>
        <div className="xl:ml-auto">
          <ButtonWithInput
            placeholder="Введите свой email"
            width="w-[450px] 3xl:w-[530px]"
          />
        </div>
      </div>

      <div className="m:hidden flex flex-col items-center rounded-[86px] border-black border-[1px] px-10 mt-12 pt-3 pb-[26px]">
        <img src={car} alt="car" className="w-[72px]" />
        <h4 className="text-[#303024] font-black mt-3 mb-2.5">
          ПОПРОБОВАТЬ ПРИЛОЖЕНИЕ
        </h4>
        <Button
          onClick={handleClick}
          padding="px-5"
          width="w-[85%] sm:w-[40%] "
          background="bg-[#AACC24]"
        >
          Демо-версия
        </Button>
      </div>
    </section>
  );
};

export default UsageRate;
