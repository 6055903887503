import React, { ReactNode, MouseEventHandler } from "react";

interface ButtonProps {
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  background?: string;
  width?: string;
  margin?: string;
  textSize?: string;
  padding?: string;
  type?: "button" | "submit";
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  background,
  width,
  margin,
  textSize,
  padding,
  type,
  disabled = false,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`
        ${width} ${background} ${margin} ${textSize} bg-[#A7D43A] text-white xl:text-[16px] text-[14px] font-normal xl:py-3.5 py-[11.5px] ${padding} rounded-[54px]
      `}
    >
      {children}
    </button>
  );
};

export default Button;
