import React from "react";

import FormHeader from "@components/formHeader/FormHeader";
import SubmitButton from "@components/submitButton/SubmitButton";
import SuccessModal from "@components/successModal/SuccessModal";
import { useContactForm } from "@hooks/useContactForm";

import car from "@assets/carBig.png";

const ContactForm: React.FC = () => {
  const {
    formData,
    isSubmitted,
    isLoading,
    handleInputChange,
    handleSubmit,
    setIsSubmitted,
  } = useContactForm();

  return (
    <section
      id="contact"
      className="relative 1xl:mx-5 mx-6 md:mx-12 bg-[#2E2E2E] 1xl:pt-[53px] pt-5 md:pt-7 lg:pt-10 1xl:pl-[50px] lg:pl-10 md:pl-7 pl-[18px] 1xl:pb-14 lg:pb-10 md:pb-6 pb-[22px] 1xl:pr-8 lg:pr-10 md:pr-7 pr-5 1xl:mt-[103px] mt-12 rounded-[16px]"
    >
      <FormHeader />
      <img
        src={car}
        alt="car"
        className="hidden 1xl:flex absolute right-8 top-[40%] 3xl:top-[32%] w-[26%]"
      />
      <form onSubmit={handleSubmit} noValidate>
        <textarea
          placeholder="Ваш вопрос или комментарий"
          name="comment"
          value={formData.comment}
          onChange={handleInputChange}
          className="mt-8 w-full block 1xl:w-[61%] 1px:py-5 md:py-4 py-2.5 1xl:px-8 px-4 md:px-7 rounded-[17px] 1xl:mb-4 md:mb-3 mb-2 placeholder-black 1xl:text-xl sm:text-base text-[14px] font-light focus:outline-none"
          rows={3}
          maxLength={1000}
        />
        <div className="hidden md:flex 1xl:w-[60.5%] md:w-full">
          <input
            type="email"
            name="email"
            placeholder="Введите свой email"
            value={formData.email}
            onChange={handleInputChange}
            className="1xl:p-5 pl-7 p-4 w-full mr-auto rounded-[54px] placeholder-black text-base 1xl:text-xl font-light"
            required
          />
          <div className="flex w-full justify-center items-center flex-col m:flex-row drop-shadow-[2px_2px_2px_rgba(45,44,42,0.1)]">
            <input
              name="phoneNumber"
              placeholder="Ваш номер телефона"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              className="xl:p-5 m:p-4 p-3 m:pl-7 pl-[19px] ml-4 rounded-[54px] focus:outline-none w-[500px] md:w-[480px] xl:w-[600px] lg:w-[520px] 3xl:w-[560px] placeholder-black 1xl:text-xl text-[14px] sm:text-base font-light"
              pattern="\+[0-9]{10,15}"
              title="Пожалуйста, введите номер телефона в формате +79221110500"
            />
            <SubmitButton isLoading={isLoading} />
          </div>
        </div>
        <div className="md:hidden flex w-full justify-center items-center flex-col m:flex-row drop-shadow-[2px_2px_2px_rgba(45,44,42,0.1)]">
          <input
            type="email"
            name="email"
            placeholder="Введите свой email"
            value={formData.email}
            onChange={handleInputChange}
            className="xl:p-5 m:p-4 p-3 m:pl-7 pl-[19px] rounded-[54px] focus:outline-none w-full placeholder-black 1xl:text-xl text-[14px] sm:text-base font-light"
            required
          />
          <SubmitButton isLoading={isLoading} />
        </div>
      </form>
      {isSubmitted && <SuccessModal onClose={() => setIsSubmitted(false)} />}
      <img
        src={car}
        alt="car"
        className="1xl:hidden flex w-[180px] lg:w-[250px] xl:w-[280px] mx-auto mt-[22px] lg:mt-8"
      />
    </section>
  );
};

export default ContactForm;
