import React from "react";

interface IMapsCard {
  imgSrc: string;
  imgAlt: string;
  title: string;
  description: string;
}

const MapsCard: React.FC<IMapsCard> = ({
  imgSrc,
  imgAlt,
  title,
  description,
}) => {
  return (
    <figure className="flex flex-col text-center justify-center max-w-[300px]">
      <img
        className="mx-auto w-full max-w-[132px] m:max-w-[180px] md:max-w-[150px] lg:max-w-[200px] 1xl:max-w-[242px]"
        src={imgSrc}
        alt={imgAlt}
      />
      <h4 className="text-center 1xl:font-semibold font-medium text-[#2D2C2A] 1xl:w-[290px] ">
        {title}
      </h4>
      <h6 className="1xl:w-[280px] w-[220px] lg:w-[240px] xl:w-[260px] 1xl:mt-2 mt-1 1xl:font-semibold font-normal text-[#2D2C2A]">
        {description}
      </h6>
    </figure>
  );
};

export default MapsCard;
