import React from "react";
import Button from "@components/button/Button";
import { Loader } from "@components/loader/Loader";

interface SubmitButtonProps {
  isLoading: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ isLoading }) => (
  <Button
    type="submit"
    textSize="1xl:text-[20px] sm:text-base"
    width="m:w-[180px] w-full"
    margin="1xl:ml-[-185px] xl:ml-[-187px] m:ml-[-185px] m:mt-0 mt-2"
    background="bg-[#AACC24]"
    disabled={isLoading}
  >
    {isLoading ? <Loader /> : "Отправить"}
  </Button>
);

export default SubmitButton;
