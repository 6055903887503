export class EmailService {
  static async sendEmailAsync(
    email: string,
    comment?: string,
    phoneNumber?: string,
  ) {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const endpoint = "/api/send-email";

    const response = await fetch(`${baseUrl}${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, comment, phoneNumber }),
    });

    return await response.json();
  }
}
