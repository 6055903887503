import React from "react";
import { ReactComponent as Cross } from "@assets/cross.svg";
import texture from "@assets/texture.png";

interface SuccessModalProps {
  onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ onClose }) => (
  <div className="absolute inset-0 bg-[#2E2E2E] bg-opacity-[95%] rounded-[16px] flex items-center justify-center z-50">
    <div className="bg-[#F8EFE8] w-[90%] sm:w-[60%] m:w-[50%] p-10 rounded-[18px] text-center relative">
      <div
        className="absolute inset-0 z-40 bg-repeat bg-center opacity-[5%]"
        style={{ backgroundImage: `url(${texture})` }}
      />
      <button
        onClick={onClose}
        className="absolute top-3.5 right-3.5 text-gray-500 z-50"
      >
        <Cross className="h-[14px] w-[14px]" />
      </button>
      <h4 className="text-[#2E2E2E] font-black">ВАШЕ СООБЩЕНИЕ</h4>
      <h4 className="text-[#2E2E2E] font-black">УСПЕШНО ОТПРАВЛЕНО</h4>
      <h5 className="mt-1.5 text-[#2E2E2E]">
        скоро с вами свяжется наш менеджер
      </h5>
    </div>
  </div>
);

export default SuccessModal;
