import React from "react";

import audiScreen from "@assets/audiScreen.png";
import colScreen from "@assets/colScreen.png";
import screenBotFirst from "@assets/screenBotFirst.png";
import screenBotSecond from "@assets/screenBotSecond.png";
import screenBotThird from "@assets/screenBotThird.png";
import {
  PROBLEMS,
  SOLUTION_CHAT,
  SOLUTION_CRM_SISTEM,
} from "@constants/constants";
import AnimatedElement from "@components/animatedElement/AnimatedElement";
import Button from "@components/button/Button";

const SolutionScreen = () => {
  const handleClick = () => {
    const element = document.getElementById("contact");
    if (element !== null) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  return (
    <>
      <section className="flex flex-col items-start 1xl:-mt-48 mt-[240px] md:-mt-24 1xl:px-5 px-6 md:px-12">
        <AnimatedElement
          id="heading"
          animationIn="animate-slideInLeft1s opacity-100"
        >
          <h2 className="max-w-[840px] md:max-w-[500px] lg:max-w-[700px] 1xl:max-w-[840px] w-auto text-left text-[#303024]">
            <span className="inline-block relative text-transparent 1xl:text-s-3 xs:text-s-3-xs">
              ОСНОВНЫЕ ПРОБЛЕМЫ,
            </span>
            <br />С КОТОРЫМИ СТАЛКИВАЮТСЯ ВЛАДЕЛЬЦЫ АВТОБИЗНЕСОВ:
          </h2>
        </AnimatedElement>
        <AnimatedElement
          id="list"
          animationIn="animate-slideInLeft8ms opacity-100"
        >
          <ul className="mt-4 mb-6 md:max-w-[440px] xl:max-w-full max-w-full">
            {PROBLEMS.map((problem) => (
              <li key={problem}>{problem}</li>
            ))}
          </ul>
        </AnimatedElement>
        <AnimatedElement
          id="button"
          animationIn="animate-slideInLeft6ms opacity-100"
        >
          <div>
            <Button
              onClick={handleClick}
              padding="px-10"
              textSize="text-[15px]"
            >
              У меня есть такие :(
            </Button>
          </div>
        </AnimatedElement>
      </section>

      <section
        id="solution"
        className="flex flex-col-reverse md:flex-row items-start 1xl:mt-48 xl:mt-40 md:mt-24 mt-12 gap-1 md:gap-24 1xl:gap-1 1xl:px-5 px-6 md:px-12"
      >
        <figure className="relative flex sm:justify-center md:mr-auto md:-ml-4 md:-mt-10 1xl:-mt-32 xl:-mt-24 -mt-4 ml-20">
          <img
            src={colScreen}
            alt="colScreen"
            className="1xl:max-w-[476px] max-w-[60%] sm:max-w-[50%] md:max-w-[280px] xl:max-w-[350px]"
          />
          <img
            src={audiScreen}
            alt="audiScreen"
            className="absolute top-[40%] 1xl:left-[50%] sm:left-[60%] left-[40%] 1xl:max-w-[385px] xl:max-w-[300px] md:max-w-[200px] max-w-[50%] sm:max-w-[40%]"
          />
        </figure>
        <div className="flex flex-col justify-end items-start mt-0">
          <AnimatedElement
            id="crmheading"
            animationIn="animate-slideInRight1s opacity-100"
          >
            <h2 className="max-w-[800px] w-auto text-left text-[#303024]">
              <span className="text-transparent 1xl:text-s-3 xs:text-s-3-xs">
                РЕШЕНИЕ, КОТОРОЕ МЫ <br /> ПРЕДЛАГАЕМ{" "}
              </span>
              CRM-СИСТЕМА
            </h2>
          </AnimatedElement>
          <AnimatedElement
            id="crmlist"
            animationIn="animate-slideInRight6ms opacity-100"
          >
            <ul className="list-disc pl-6 1xl:mt-8 mt-4">
              {SOLUTION_CRM_SISTEM.map((solution) => (
                <li key={solution}>{solution}</li>
              ))}
            </ul>
          </AnimatedElement>
        </div>
      </section>

      <section className="flex flex-col md:flex-row md:items-start md:mt-10 xl:mt-16 1xl:mt-[125px] md:gap-4 1xl:gap-16 1xl:px-5 px-6 md:px-12">
        <div className="flex flex-col flex-1">
          <AnimatedElement
            id="chatheading"
            animationIn="animate-slideInLeft1s opacity-100"
          >
            <h2 className="max-w-[800px] w-auto xl:mt-20 md:mt-14 mt-3 text-left text-[#303024]">
              РЕШЕНИЕ, КОТОРОЕ МЫ <br /> ПРЕДЛАГАЕМ{" "}
              <span className="text-transparent 1xl:text-s-3 text-s-3-xs">
                ЧАТ-БОТ
              </span>
            </h2>
          </AnimatedElement>
          <AnimatedElement
            id="chatlist"
            animationIn="animate-slideInLeft6ms opacity-100"
          >
            <ul className="list-disc pl-6 1xl:mt-8 md:max-w-[400px] 1xl:max-w-full mt-4 text-left whitespace-pre-line">
              {SOLUTION_CHAT.map((solution) => (
                <li key={solution}>{solution}</li>
              ))}
            </ul>
          </AnimatedElement>
        </div>
        <figure className="relative flex flex-1 ml-12 sm:ml-32 md:mr-auto md:-ml-10 1xl:ml-12 mt-3.5 1xl:mt-0">
          <img
            src={screenBotFirst}
            alt="screenBotFirst"
            className="1xl:max-w-[273px] max-w-[35%] sm:max-w-[30%] md:max-w-[200px] xl:max-w-[240px]"
          />
          <img
            src={screenBotThird}
            alt="screenBotThird"
            className="absolute md:top-0 md:left-[30%] z-10 1xl:max-w-[233px] xl:max-w-[200px] sm:max-w-[25%] max-w-[30%] md:max-w-[180px] top-[3%] left-[23%] sm:left-[18%]"
          />
          <img
            src={screenBotSecond}
            alt="screenBotSecond"
            className="absolute h-auto md:left-[53%] 1xl:top-[-10%] 1xl:max-w-[324px] xl:max-w-[255px] md:max-w-[220px] sm:max-w-[35%] max-w-[40%] left-[42%] sm:left-[34%] -top-[5%]"
          />
        </figure>
      </section>
    </>
  );
};

export default SolutionScreen;
