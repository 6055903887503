import React from "react";

import { BUTTONS } from "@constants/constants";

interface IButtonProps {
  text: string;
  bgColor?: string;
  textColor?: string;
  borderColor?: string;
}

const Button: React.FC<IButtonProps> = ({
  text,
  bgColor,
  textColor,
  borderColor,
}) => {
  return (
    <button
      className={`xl:py-3.5 py-2.5 px-5 3xl:px-8 mx-1 rounded-[89px] xl:text-[16px] text-[14px] font-normal ${bgColor} ${textColor} ${borderColor} border-[1px] whitespace-nowrap`}
    >
      {text}
    </button>
  );
};

const ButtonList = () => {
  const repeatedButtons = [...BUTTONS, ...BUTTONS, ...BUTTONS];

  return (
    <div className="overflow-hidden relative flex flex-col items-center">
      <div className="flex animate-marquee whitespace-nowrap">
        {repeatedButtons.map((button, index) => (
          <Button
            key={index}
            text={button.text}
            bgColor={button.bgColor}
            textColor={button.textColor}
            borderColor={button.borderColor}
          />
        ))}
      </div>
      <div className="flex animate-marqueeReverse whitespace-nowrap mt-1 lg:mt-2">
        {repeatedButtons.map((button, index) => (
          <Button
            key={index}
            text={button.text}
            bgColor={button.bgColor}
            textColor={button.textColor}
            borderColor={button.borderColor}
          />
        ))}
      </div>
    </div>
  );
};

export default ButtonList;
