import React from "react";

import ContactForm from "@sections/ContactForm";
// import Feedback from "@sections/Feedback";
import Footer from "@layout/Footer";
import Header from "@layout/Header";
import Heading from "@sections/Heading";
import QuestionsBlock from "@sections/QuestionsBlock";
import RouteOnMap from "@sections/RouteOnMap";
import SolutionScreen from "@sections/SolutionScreen";
import UsageRate from "@sections/UsageRate";
import VideoBannerWithStats from "@sections/VideoBannerWithStats";

import texture from "@assets/texture.png";

const HomePage = () => {
  return (
    <section className="relative w-full min-h-screen">
      <div
        className="absolute inset-0 bg-repeat bg-center opacity-5"
        style={{ backgroundImage: `url(${texture})` }}
      />
      <div className="relative mx-auto 1xl:max-w-[1440px] lg:max-w-[1024px] xl:max-w-[1200px] z-10 overflow-hidden">
        <Header />
        <main className="relative -z-50">
          <Heading />
          <RouteOnMap />
          <SolutionScreen />
          <VideoBannerWithStats />
          {/*<Feedback />*/}
          <UsageRate />
          <QuestionsBlock />
          <ContactForm />
        </main>
      </div>
      <footer className="relative bg-[#2E2E2E] z-10">
        <Footer />
      </footer>
    </section>
  );
};

export default HomePage;
