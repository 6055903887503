import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";

import { NAVIGATION } from "@constants/constants";

import { ReactComponent as Category } from "@assets/category.svg";
import { ReactComponent as Cross } from "@assets/cross.svg";
import texture from "@assets/texture.png";

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleNav = () => {
    setIsNavOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (isNavOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isNavOpen]);

  useEffect(() => {
    const handleAnimationEnd = () => {
      setAnimationComplete(true);
    };

    const header = document.querySelector("header");
    if (header) {
      header.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      if (header) {
        header.removeEventListener("animationend", handleAnimationEnd);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`flex justify-between items-center 1xl:h-[28px] h-[17px] 1xl:px-5 px-6 md:px-12 md:pt-10 m:pt-8 lg:pt-[50px] pt-8  ${!animationComplete ? "transition-transform duration-2000 animate-slideInTop" : ""}`}
    >
      <h4>AUTOFLOW</h4>
      <div className="block m:hidden" onClick={toggleNav}>
        <div
          className={`fixed h-12 w-12 py-[15px] right-7 top-3 px-2  transition-transform duration-500 ease-in-out ${isScrolled ? "rounded-full bg-white shadow-[0_4px_4px_0_rgba(101,101,101,0.14)]" : " "} ${
            isNavOpen ? "opacity-0 transform rotate-90" : "opacity-100"
          }`}
        >
          <Category />
        </div>
      </div>
      <nav className="hidden m:flex xl:space-x-[35px] space-x-4 border-b border-transparent">
        {NAVIGATION.map((item) => (
          <Link
            key={item.label}
            to={item.id}
            spy={true}
            smooth={true}
            offset={-75}
            duration={1000}
            className="text-[#2D2C2A] cursor-pointer tracking-wide hover:text-s-3-xs "
          >
            {item.label}
          </Link>
        ))}
      </nav>
      <div
        className={`fixed w-full h-screen top-0 right-0 z-10 flex flex-col items-center transition-transform duration-500 ease-in-out ${
          isNavOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div
          className="absolute z-50 top-0 right-9 pt-[22px] sm:pt-[21px]"
          onClick={toggleNav}
        >
          <Cross className="animate-cross" />
        </div>
        <div className="absolute z-50 top-0 left-6 pt-[22px] sm:pt-[21px]">
          <h4>AUTOFLOW</h4>
        </div>
        <div
          className="absolute inset-0 z-40 bg-repeat bg-center opacity-[5%]"
          style={{ backgroundImage: `url(${texture})` }}
        />
        <div className="absolute bg-[#f6f0e1] h-full w-full" />
        <ul className="flex flex-col z-50 items-center mt-32 justify-between min-h-[250px]">
          {NAVIGATION.map((item) => (
            <li key={item.label} className="my-3 uppercase font-bold">
              <Link
                activeClass="active"
                to={item.id}
                spy={true}
                smooth={true}
                offset={-10}
                duration={1000}
                onClick={toggleNav}
                className="cursor-pointer"
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
};

export default Header;
