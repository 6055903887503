import React from "react";

const FormHeader: React.FC = () => (
  <h2 className="text-white text-[16px] sm:text-[24px] lg:text-[32px] 1xl:text-[48px] font-bold sm:font-black">
    ОТКРОЙТЕ НОВУЮ ЭРУ ДЛЯ <br />
    <span className="text-transparent 1xl:text-white-2 text-white-2-xs">
      ПРОФЕССИОНАЛОВ В СФЕРЕ <br />
    </span>
    АВТОИМПОРТА ВМЕСТЕ С AUTOFLOW
  </h2>
);

export default FormHeader;
