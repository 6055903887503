import userImage1 from "@assets/userImage1.png";
import userImage2 from "@assets/userImage2.png";
import userImage3 from "@assets/userImage3.png";

export const NAVIGATION = [
  { id: "solution", label: "Решение" },
  { id: "how-it-works", label: "Как это работает?" },
  { id: "pricing", label: "Стоимость" },
  { id: "faq", label: "FAQ" },
  { id: "contacts", label: "Контакты" },
];

export const PROBLEMS = [
  "- сложности в управлении заказами,",
  "- неэффективное распределение задач,",
  "- потеря клиентов из-за недостаточной коммуникации после заключения сделки,",
  "- сбор аналитики и составление отчетов,",
  "- нет возможности интеграции программ,",
  "- сложность в настройке рекламных компаний,",
  "- документооборот.",
];

export const SOLUTION_CRM_SISTEM = [
  "Ведете всю базу клиентов в одном месте",
  "Настраиваете эффективную воронку продаж",
  "Постановка и контроль за выполнением задач/проектов",
  "Автоматический сбор аналитики и составление отчетов",
  "Электронный документооборот с выводом документов на печать",
  "Составление КП прямо в карточке клиента",
  "Интеграция системы с нужными для вас программами",
];

export const SOLUTION_CHAT = [
  "Ведет коммуникацию с клиентом после заключения сделки",
  `Быстро и вовремя оповещает клиента о статусе сделки и \n необходимой ему информации`,
  "Работает 24/7",
  "Всегда вежливый и в хорошем настроении :)",
];

export const FEEDBACKS = [
  {
    id: 0,
    name: "ДИАНА СТАРОВОЙТОВА",
    role: "UI/UX DESIGNER",
    image: userImage1,
    text: `“Благодаря autoflow, конверсия продаж выросла на 30%, а количество довольных клиентов увеличилось в 2 раза! <br><br> Теперь мои сотрудники не тратят время на заполнение карточек, т.к. Информация автоматически подтягивается в систему, благодаря боту. А клиенты всегда довольны в обслуживании, ведь они всегда вовремя получают необходимую им информацию.”`,
  },
  {
    id: 1,
    name: "СЕРГЕЙ ИВАНОВ",
    role: "UI/UX DESIGNER",
    image: userImage2,
    text: `“Благодаря autoflow, конверсия продаж выросла на 30%, а количество довольных клиентов увеличилось в 2 раза! Теперь мои сотрудники не тратят время на заполнение карточек, т.к. Информация автоматически подтягивается в систему, благодаря боту. А клиенты всегда довольны в обслуживании, ведь они всегда вовремя получают необходимую им информацию.”`,
  },
  {
    id: 2,
    name: "АННА ПЕТРОВА",
    role: "UI/UX DESIGNER",
    image: userImage3,
    text: `“Благодаря autoflow, конверсия продаж выросла на 30%, а количество довольных клиентов увеличилось в 2 раза! Теперь мои сотрудники не тратят время на заполнение карточек, т.к. Информация автоматически подтягивается в систему, благодаря боту. <br><br> А клиенты всегда довольны в обслуживании, ведь они всегда вовремя получают необходимую им информацию.”`,
  },
  {
    id: 3,
    name: "АЛЕКСАНДР СМИРНОВ",
    role: "UI/UX DESIGNER",
    image: userImage2,
    text: `“Благодаря autoflow, конверсия продаж выросла на 30%, а количество довольных клиентов увеличилось в 2 раза! <br><br> Теперь мои сотрудники не тратят время на заполнение карточек, т.к. Информация автоматически подтягивается в систему, благодаря боту. <br><br> А клиенты всегда довольны в обслуживании, ведь они всегда вовремя получают необходимую им информацию.”`,
  },
  {
    id: 4,
    name: "ОЛЬГА КУЗНЕЦОВА",
    role: "UI/UX DESIGNER",
    image: userImage1,
    text: `“Благодаря autoflow, конверсия продаж выросла на 30%, а количество довольных клиентов увеличилось в 2 раза! Теперь мои сотрудники не тратят время на заполнение карточек, т.к. Информация автоматически подтягивается в систему, благодаря боту. <br><br> А клиенты всегда довольны в обслуживании, ведь они всегда вовремя получают необходимую им информацию.”`,
  },
];

export const PLANS = [
  {
    id: "basic",
    name: "БАЗОВЫЙ",
    price: "200 BYN",
    description: "включен необходимый функционал",
    features: [
      { id: "basic-feature-1", text: "ведение клиентствой базы" },
      {
        id: "basic-feature-2",
        text: "настраиваемая канбан-доска для  создания воронки продаж",
      },
      {
        id: "basic-feature-3",
        text: "задачи и проекты",
      },
      { id: "basic-feature-4", text: "электронный документооборот" },
      {
        id: "basic-feature-5",
        text: "автоматическое обновление статуса сделки у вашего клиента",
      },
    ],
    buttonText: "Хочу этот тариф",
    buttonColor: "bg-[#303024]",
  },
  {
    id: "standard",
    name: "СТАНДАРТНЫЙ",
    price: "300 BYN",
    description: "самый популярный тариф",
    features: [
      { id: "standard-feature-1", text: "ведение клиентствой базы" },
      {
        id: "standard-feature-2",
        text: "создание сделки в CRM из чат-бота клиента",
      },
      {
        id: "standard-feature-3",
        text: "настраиваемая канбан-доска для  создания воронки продаж",
      },
      { id: "standard-feature-4", text: "задачи и проекты" },
      { id: "standard-feature-5", text: "электронный документооборот" },
      {
        id: "standard-feature-6",
        text: "настройка интереграции с системами",
      },
      {
        id: "standard-feature-7",
        text: "автоматическое обновление статуса сделки у вашего клиента",
      },
      {
        id: "standard-feature-8",
        text: "техническая поддержка",
      },
    ],
    buttonText: "Хочу этот тариф",
    buttonColor: "bg-[#C6BF8B]",
    popular: true,
  },
  {
    id: "super-pro",
    name: "ПРОФЕССИОНАЛЬНЫЙ",
    price: "600 BYN",
    description: "недоступный в данный момент",
    features: [
      { id: "super-pro-feature-1", text: "ведение клиентствой базы" },
      {
        id: "super-pro-feature-2",
        text: "создание сделки в CRM из чат-бота клиента",
      },
      {
        id: "super-pro-feature-3",
        text: "настраиваемая канбан-доска для  создания воронки продаж",
      },
      { id: "super-pro-feature-4", text: "задачи и проекты" },
      { id: "super-pro-feature-5", text: "электронный документооборот" },
      {
        id: "super-pro-feature-6",
        text: "настройка интереграции с системами",
      },
      {
        id: "super-pro-feature-7",
        text: "автоматическое обновление статуса сделки у вашего клиента",
      },
      {
        id: "super-pro-feature-8",
        text: "техническая поддержка",
      },
      {
        id: "super-pro-feature-9",
        text: "личный ассистент (ИИ)",
      },
      {
        id: "super-pro-feature-10",
        text: "расширенная отчетность",
      },
      {
        id: "super-pro-feature-11",
        text: "сквозная аналитика",
      },
      {
        id: "super-pro-feature-12",
        text: "история каждой сделки",
      },
      {
        id: "super-pro-feature-13",
        text: "коммерческие предложения",
      },
      {
        id: "super-pro-feature-14",
        text: "план продаж и маркетинг",
      },
    ],
    buttonText: "Хочу этот тариф",
    buttonColor: "bg-[#303024]",
  },
];

export const FAQ_DATA = [
  {
    id: 1,
    question: "Как будет происходить перенос данных в вашу систему?",
    answer:
      "Переносом данных будут заниматься наши специалисты, для того, чтобы сохранить корректность информации. Затраты на данную работу уже включены в стоимость вашего тарифа.",
  },
  {
    id: 2,
    question: "С какого тарифа начать?",
    answer:
      'Мы рекомендуем начать с тарифа "Стандартный", т.к. данный тариф включает все необходимые функции для работы с автобизнесом.',
  },
  {
    id: 3,
    question: "Как получить бесплатную демо версию?",
    answer:
      "Для того, чтобы получить бесплатную версию, необходимо оставить заявку на нашем сайте. Менеджер свяжется с вами в течении получаса, и предоставит доступ к системе.",
  },
  {
    id: 4,
    question: "Хочу понять, что будет происходить с моей базой данных?",
    answer:
      "Мы предоставляем отдельный инстанс системы для каждой компании. Это значит, что ваши данные хранятся изолированно и не пересекаются с данными других клиентов. При переносе ваша база данных будет полностью импортирована в вашу собственную CRM-систему, которая доступна только вам и вашим сотрудникам.\n" +
      "\n" +
      "Наши специалисты готовы бесплатно помочь вам с переносом базы данных в нашу систему. Мы предоставим вам подробные инструкции и поддержку на всех этапах процесса.",
  },
  {
    id: 5,
    question: "Возможно ли внедрение моего функционала в систему?",
    answer:
      "Да, мы готовы обсудить возможность внедрения вашего функционала. Пожалуйста, свяжитесь с нами через форму обратной связи и расскажите нам подробнее о ваших требованиях, чтобы мы могли оценить возможность реализации.",
  },
  {
    id: 6,
    question: "Как настроить чат-бот?",
    answer:
      "При запуске системы, чат-бот уже настроен под вашу CRM систему. Вам необходимо передать ссылку вашему клиенту для отслеживания статуса сделки и совершения других необходимых действий. ",
  },
  {
    id: 7,
    question:
      "Какие дополнительные расходы потребуются на внедрение вашей системы?",
    answer:
      "Внедрение нашей системы не требует дополнительных расходов! Мы стремимся сделать процесс максимально простым и доступным для наших клиентов. Вы получаете готовую к использованию систему, которая не потребует дополнительных затрат на настройку и интеграцию.",
  },
];

export const BUTTONS = [
  {
    id: 1,
    text: "повысите производительность сотрудников",
    bgColor: "bg-[#2D2C2A]",
    textColor: "text-[#F8EFE8]",
    borderColor: "border-[#2D2C2A]",
  },
  {
    id: 2,
    text: "улучшите качество обслуживания клиентов",
    bgColor: "bg-transparent",
    textColor: "text-[#000000]",
    borderColor: "border-[#303024]",
  },
  {
    id: 3,
    text: "сократите время на обработку информации",
    bgColor: "bg-[#2D2C2A]",
    textColor: "text-[#F8EFE8]",
    borderColor: "border-[#2D2C2A]",
  },
  {
    id: 4,
    text: "будете видеть ход сделки",
    bgColor: "bg-[#C6BF8B]",
    textColor: "text-[#2D2C2A]",
    borderColor: "border-[#C6BF8B]",
  },
  {
    id: 5,
    text: "автоматически соберете аналитику по работе Ваших менеджеров",
    bgColor: "bg-transparent",
    textColor: "text-[#000000]",
    borderColor: "border-[#303024]",
  },
  {
    id: 6,
    text: "увеличите конверсию продаж",
    bgColor: "bg-[#C6BF8B]",
    textColor: "text-[#2D2C2A]",
    borderColor: "border-[#C6BF8B]",
  },
];
