import React from "react";
import { Link } from "react-scroll";

import { NAVIGATION } from "@constants/constants";

import { ReactComponent as Email } from "@assets/email.svg";
import { ReactComponent as Facebook } from "@assets/facebook.svg";
import { ReactComponent as Instagram } from "@assets/instagram.svg";
import { ReactComponent as Telegram } from "@assets/telegram.svg";
import { ReactComponent as Twitter } from "@assets/twitter.svg";
import { ReactComponent as Viber } from "@assets/viber.svg";
import { ReactComponent as Whatsapp } from "@assets/whatsapp.svg";

const Footer = () => {
  return (
    <section
      id="contacts"
      className="1xl:px-5 px-6 md:px-12 1xl:mt-24 lg:mt-20 mt-12 pb-[52px] 1xl:py-12 py-6 m:py-10 flex justify-between 1xl:max-w-[1440px] lg:max-w-[1000px] xl:max-w-[1200px] mx-auto"
    >
      <div className="m:w-1/4 w-3/4">
        <h4 className="text-[#C6BF8B] m:mb-4 mb-2">КОНТАКТЫ</h4>
        <figure className="flex mb-2 m:mb-4 items-center">
          <Viber className="mr-1.5" />
          <Whatsapp className="mr-1.5" />
          <Telegram className="mr-2" />
          <h5 className="text-white whitespace-nowrap">+375 29 515 46 36</h5>
        </figure>
        <h5 className="text-white mb-2 m:mb-4">
          г.Гродно, Гродненская обл., {""} 23000, ул.Гаспадарча 23/4
        </h5>
        <figure className="flex mb-2 m:mb-4 items-center">
          <Email />
          <h5 className="text-white ml-2">contact@abyron.ru</h5>
        </figure>
      </div>

      <div className="hidden m:flex flex-col w-1/4">
        <h4 className="text-[#C6BF8B] mb-4">БЫСТРЫЕ ССЫЛКИ</h4>
        <ul>
          {NAVIGATION.map(
            (item) =>
              item.id !== "contacts" && (
                <li key={item.id} className="text-white mb-3.5">
                  <Link
                    activeClass="active"
                    to={item.id}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={1000}
                    className="cursor-pointer tracking-wide 1xl:text-[20px] 1xl:leading-[23.7px] lg:text-[16px] xl:text-[18px] text-[16px] sm:leading-[20px] hover:text-white-1"
                  >
                    {item.label}
                  </Link>
                </li>
              ),
          )}
        </ul>
      </div>
      <figure className="w-1/4 flex justify-end lg:gap-3.5 gap-2">
        <Twitter />
        <Instagram />
        <Facebook />
      </figure>
    </section>
  );
};

export default Footer;
