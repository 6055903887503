import React from "react";

interface ILine {
  borderColor?: string;
}

const Line: React.FC<ILine> = ({ borderColor }) => {
  return <div className={`lg:mt-6 mt-3 border-b-[1px] ${borderColor}`}></div>;
};

export default Line;
