import React from "react";

import AnimatedElement from "@components/animatedElement/AnimatedElement";
import MapsCard from "@components/mapsCard/MapsCard";

import { ReactComponent as RouteSVG } from "@assets/routewithpoint.svg";
import { ReactComponent as RouteMobile } from "@assets/routeWithPointMobile.svg";
import assistant from "@assets/assistant.png";
import autoBot from "@assets/autobot.png";
import car from "@assets/car.png";
import CRMSystem from "@assets/CRMSistem.png";
import map from "@assets/map.png";
import placemark from "@assets/placemark.png";
import tools from "@assets/tools.png";

const RouteOnMap = () => {
  return (
    <section className="flex justify-center mt-6 1xl:px-5 px-6 md:px-12">
      <div className="relative xl:mt-[180px] mt-32 px-20 1xl:w-[800px] w-[360px] md:w-[400px]">
        <img
          className="absolute w-[45%] sm:w-[50%] m:w-[60%] md:w-[45%] lg:w-[55%] 1xl:w-[45%] 1xl:left-[-12%] lg:-left-[40%] 1xl:top-[-2%] md:-top-[4%] m:top-[-7%] top-[-5%] left-[4%] sm:-left-[15%] m:-left-[30%]"
          src={car}
          alt="car"
        />
        <div className="relative w-full">
          <RouteSVG className="hidden md:flex 1xl:w-[900px] md:w-[400px] lg:w-[500px] 1xl:right-32 xl:w-[600px] xl:right-44 lg:right-32 right-20 relative z-10" />
          <RouteMobile className="md:hidden w-[264px] right-10 relative z-10" />
          <div className="absolute md:top-0 top-[7%] 1xl:w-[50%] 1xl:left-[722px] left-[27%] sm:left-[70%] m:left-[90%] md:left-[120%] lg:left-[150%] xl:left-[165%] sm:top-[9%]">
            <AnimatedElement
              id="autoBot"
              animationIn="animate-slideInRight6ms opacity-100"
            >
              <MapsCard
                imgSrc={autoBot}
                imgAlt="autobot"
                title="АВТОМАТИЧЕСКИЙ БОТ"
                description="который общается с клиентами поcле заключения сделки"
              />
            </AnimatedElement>
          </div>
          <div className="absolute 1xl:top-[17%] md:top-[20%] top-[28%] 1xl:left-[-63%] xl:-left-[175%] lg:left-[-150%] md:left-[-120%] m:-left-[100%] sm:-left-[80%] -left-[50%] 1xl:w-[50%]">
            <AnimatedElement
              id="CRMSystem"
              animationIn="animate-slideInLeft6ms opacity-100"
            >
              <MapsCard
                imgSrc={CRMSystem}
                imgAlt="CRMSystem"
                title="CRM СИСТЕМА"
                description="специализированная CRM система для автобизнеса"
              />
            </AnimatedElement>
          </div>
          <div className="absolute 1xl:w-[50%] 1xl:top-[45%] 1xl:left-0 xl:top-[38%] lg:top-[32%] top-[49%] md:top-[30%] left-[25%] sm:left-[70%] m:left-[90%] md:left-[-22%] lg:-left-[35%] xl:-left-[50%]">
            <AnimatedElement
              id="tools"
              animationIn="animate-slideInLeft8ms opacity-100"
            >
              <MapsCard
                imgSrc={tools}
                imgAlt="tools"
                title="ИНСТРУМЕНТЫ"
                description="для оптимизации работы и экономии времени сотрудников"
              />
            </AnimatedElement>
          </div>
          <div className="absolute 1xl:top-[37%] md:top-[40%] m:top-[65%] top-[69%] 1xl:w-[50%] -left-[40%] sm:-left-[60%] m:-left-[100%] md:left-[130%] lg:left-[155%] xl:left-[175%] 1xl:left-[722px]">
            <AnimatedElement
              id="assistant"
              animationIn="animate-slideInRight8ms opacity-100"
            >
              <MapsCard
                imgSrc={assistant}
                imgAlt="personal assistant"
                title="ЛИЧНЫЙ АССИСТЕНТ"
                description="для владельцев автобизнеса"
              />
            </AnimatedElement>
          </div>
          <figure className="hidden md:flex absolute z-0 1xl:w-full w-[150%] xl:w-[200%] 1xl:left-[380px] lg:left-[68%] left-[50%] bottom-[-30%] lg:-bottom-[25%] 1xl:bottom-[-22%] opacity-60">
            <img className="-z-10" src={map} alt="map" />
          </figure>
          <h6 className="absolute 1xl:left-[107%] xl:left-[145%] lg:left-[120%] md:left-[107%] left-[6%] bottom-[-2%] 1xl:bottom-[-2%] xl:-bottom-[2.5%] md:bottom-[-4%] w-[200px] font-semibold text-[#2D2C2A]">
            Беларусь, Россия
          </h6>
          <figure className="absolute 1xl:w-[10%] w-[20%] 1xl:left-[112.8%] xl:left-[162%] lg:left-[141%] md:left-[120.5%] left-[30%] bottom-[2.5%]">
            <img src={placemark} alt="placemark" />
          </figure>
        </div>
        <figure className="md:hidden absolute box-content -z-10 w-[150%] object-contain h-auto bottom-[-23%] -left-[35%] opacity-60">
          <img className="-z-10" src={map} alt="map" />
        </figure>
        <h6 className="absolute 1xl:top-28 1xl:left-[45%] top-[3%] left-[60%] md:top-[10%] md:left-[45%] transform -translate-x-1/2 font-semibold">
          Китай, США, Европа
        </h6>
      </div>
    </section>
  );
};

export default RouteOnMap;
