import React from "react";
import ButtonWithInput from "@components/buttonWithInput/ButtonWithInput";

const Heading = () => {
  return (
    <>
      <section className="flex justify-center items-center xl:mt-[228px] mt-[105px] 1xl:px-5 px-6 md:px-12">
        <h1
          className={`max-w-[1285px] w-auto m:text-center text-left transition-transform duration-1000 animate-slideInTop`}
        >
          УВЕЛИЧЬТЕ ЭФФЕКТИВНОСТЬ
          <span className="inline-block relative 1xl:mx-[10px] text-transparent 1xl:text-s-4 xl:text-s-2 xs:text-s-4-xs">
            ВАШЕГО АВТОБИЗНЕСА С
          </span>{" "}
          AUTOFLOW!
        </h1>
      </section>
      <h5
        className={`hidden m:flex max-w-[823px] text-center mx-auto mt-6 1xl:px-5 px-6 md:px-12 transition-transform duration-1000 animate-slideInTop15ms`}
      >
        AutoFlow – это система, разработанная специально для автобизнеса,
        которая повышает эффективность сотрудников и качество обслуживания
        клиентов
      </h5>
      <h5
        className={`flex m:hidden text-left mt-4 px-6 transition-transform duration-1000 transform animate-slideInTop15ms`}
      >
        это система, разработанная специально для автобизнеса, которая повышает
        эффективность сотрудников и качество обслуживания клиентов
      </h5>
      <section
        className={`flex mr-2 items-center justify-center 1xl:mt-6 mt-[34px] 1xl:px-5 px-6 md:px-12 mx-auto transition-transform duration-1000 animate-slideInTop15ms`}
      >
        <ButtonWithInput
          placeholder="Введите свой email"
          width="xl:w-[550px] w-full m:w-[450px]"
        />
      </section>
    </>
  );
};

export default Heading;
